$(document).ready(function () {
  var $header = $(".header"),
    $body = $("body"),
    headerClass = "header-fixed",
    bodyClass = "body-header-fixed",
    headerSize = $header.height();

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();

    if (scroll > 0) {
      $header.addClass(headerClass);
    } else {
      $header.removeClass(headerClass);
      $body.removeClass(bodyClass).removeAttr("style");
    }
  });
});
