$(document).ready(function () {
  var $thumbnails = $(".gallery-thumbnails-content"),
    $buttonUp = $(".button-scroll-up"),
    $buttonDown = $(".button-scroll-down"),
    speed = 100;

  $(".gallery-thumbnail-item img").waitUntilExists(function () {
    if ($(".product-thumbnails > li").length > 4) {
      $buttonUp.removeClass("hidden");
      $buttonDown.removeClass("hidden");
    }
  });

  $buttonUp.on("click", function () {
    $thumbnails.animate({ scrollTop: "-=93px" }, speed);
  });

  $buttonDown.on("click", function () {
    $thumbnails.animate({ scrollTop: "+=93px" }, speed);
  });
});
