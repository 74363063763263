window.onload = function () {
    var $formQuestion = $("#product-new-question-form");
  
    function errorResponse() {
      $formQuestion.find("input").removeAttr("disabled");
      $formQuestion.find('input[type="submit"]').val("Perguntar");
    }
  
    function sendQuestion() {
      var form = $formQuestion;
      var formData = form.serialize();
      var url = form.attr("action");
      var question_input = form.find('input[name="content"]');
      var question = question_input.val();
  
      if (question === "") {
        if (!question_input.hasClass("error")) {
          question_input.addClass("error");
          question_input.after(
            '<span class="text-danger">Escreva sua pergunta</span>'
          );
        }
        return false;
      }
  
      $.ajax({
        method: "POST",
        url: url,
        data: formData,
        dataType: "json",
        beforeSend: function () {
          form.find("input").attr("disabled", "disabled");
          form.find('input[type="submit"]').val("Aguarde...");
        },
        success: function (data) {
          var h = $("<h4>").text(data.message);
          switch (data.success) {
            case true:
              h.addClass("text-success");
              break;
            default:
              h.addClass("text-danger");
          }
          form.html(h);
        },
        error: function () {
          errorResponse();
        },
      });
      return false;
    }
  
    function validaCaptcha() {
      var classInvisible = $formQuestion.find(".v-invisible");
      var classInvisibleTrue = $formQuestion.find(".v-invisible-true");
  
      if (typeof grecaptcha == "object") {
        // Possui captcha habilitado
  
        // Verifica o tipo de captcha
        if (classInvisible.length != 0) {
          //Captcha do tipo "invisível"
  
          // Verifica se o captcha já foi validado
          if (classInvisibleTrue.length == 0) {
            // Valida o captcha, pois ainda não foi validado
            grecaptcha.execute();
          }
        } else {
          //Captcha do tipo "v2"
  
          // Captcha do tipo "V2" ainda não validado
          var respCaptcha = grecaptcha.getResponse();
          if (respCaptcha.length == 0) {
            // Caso a validação  falhar retorna erro
            alert(
              "Não foi possível validar o captcha, por favor, confirme se você não é um robô!"
            );
            errorResponse();
            return false;
          }
        }
      }
  
      return sendQuestion();
    }
  
    $("#product-new-question-form").on("submit", function () {
      return validaCaptcha();
    });
  
    $formQuestion.on("keypress", function (e) {
      var keyCode = e.keyCode || e.which;
      if (keyCode === 13) {
        e.preventDefault();
        return validaCaptcha();
      }
    });
  };