$(document).ready(function(){
  setTimeout(function(){
    $('.loading-video').on('click', function(){
      var videoSrc =  $(this).data('video');
      videoSrc = videoSrc.replace('watch?v=','embed/');
      var html = '<iframe src="'+videoSrc+'" '+
      'width="100%" height="100%"'+
      'title="YouTube video player" frameborder="0" '+
      'allow="accelerometer; autoplay; clipboard-write;'+
      ' encrypted-media; gyroscope; picture-in-picture"'+
      ' allowfullscreen></iframe>';
      $(this).parent().html(html);
    });
  }, 2000)
});