var colorThief = new ColorThief();

$(window).load(function () {
  $(".slides-home .item").each(function () {
    var sourceImage = $("img", this)[0],
      color = colorThief.getColor(sourceImage);

    $(this).css("background-color", "rgb(" + color + ")");
  });
});
