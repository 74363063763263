$(document).ready(function () {
  $(".change-column-item").on("click", function () {
    var $el = $(this),
      value = $($el).attr("data-value"),
      $columns = $(".shop-area-list-desktop"),
      $banners = $(".grid-banners"),
      $productBoxContainer = $columns.find(".fx, .product-box-container"),
      $banners01 = $(".banner-category-1").first(),
      $banners02 = $(".banner-category-2").first(),
      $banners03 = $(".banner-category-3").first();

    $banners.remove();

    if (value === 6 || value === "6") {
      if ($banners01.length) $productBoxContainer.eq(5).after($banners01);
      if ($banners02.length) $productBoxContainer.eq(11).after($banners02);
      if ($banners03.length) $productBoxContainer.eq(15).after($banners03);
    }

    if (value === 4 || value === "4") {
      if ($banners01.length) $productBoxContainer.eq(5).after($banners01);
      if ($banners02.length) $productBoxContainer.eq(11).after($banners02);
      if ($banners03.length) $productBoxContainer.eq(17).after($banners03);
    }

    if (value === 3 || value === "3") {
      if ($banners01.length) $productBoxContainer.eq(7).after($banners01);
      if ($banners02.length) $productBoxContainer.eq(15).after($banners02);
      if ($banners03.length) $productBoxContainer.eq(19).after($banners03);
    }
  });
  $('.shop-area-list-mobile .product-box').addClass('showcase-product-box');
  $("#column-item-active").click();
});
