var $galeryThumbs = $(".gallery-thumbnails-content"),
  $galeryLi = $("li", $galeryThumbs),
  currentClassThumb = "current-image";

$galeryLi.first().addClass(currentClassThumb);

$(document).on(
  "mouseover",
  ".product-thumbnail-item-image, .product-thumbnail-item__video",
  function () {
    var $photoMain = $(".product-photo-main"),
      classVideo = "product-photo-main__video";

    if (this.parentElement.hasAttribute("id")) {
      $photoMain.addClass(classVideo);
    } else {
      $photoMain.removeClass(classVideo);
    }

    $galeryLi.removeClass(currentClassThumb);
    $(this).parent().addClass(currentClassThumb);
  }
);
